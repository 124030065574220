<template>
  <div>
    <div class="mr-10">
      <div class="vx-row w-full" style="margin: auto">
        <div class="vx-col mt-4  lg:w-1/2 w-full" >
        
                    <imageLazy
                      :imageStyle="{ 'border-radius': '10px','height': '300px' }"
                      :imageClass="'w-full'"
                      :src="baseURL + hospital.ImagePath"
                      placeHolderType="hospital"
                    />
        </div>
        <div class="vx-col lg:w-1/2 w-full" >
          <div div class="w-full justify-center">
            <h2 class="mt-4 ml-12">
              {{ hospital.Name }}
            </h2>
            <h6 v-if="!$acl.check('patient')&&!$acl.check('anonymous')" class="mt-2 ml-12">{{ hospital.Email }}</h6>
            <h6 v-if="!$acl.check('patient')&&!$acl.check('anonymous')" class="mt-2 ml-12">{{ hospital.Phone }}</h6>
            <div class="vx-row mt-2 ml-12">
              <h5 class="lg:w-1/2" style="color: #696688">
                {{ $t("Rate") }} :
              </h5>
              <star-rating
                class=""
                :rtl="$vs.rtl"
                :star-size="25"
                v-model="hospital.Rate"
                :border-width="2"
                :disable="true"
                border-color="#d8d8d8"
                :rounded-corners="false"
                :star-points="[
                  23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34,
                  46, 19, 31, 17,
                ]"
                :read-only="true"
                :show-rating="false"
              ></star-rating>
            </div>

            <div class="vx-row mt-2 ml-12">
              <h5 class="lg:w-1/2 mb-5" style="color: #696688">
                {{ $t("RecommendationLevel") }} :
              </h5>

              <star-rating
                class="mb-5"
                :rtl="$vs.rtl"
                :star-size="25"
                v-model="hospital.RecommendedLevel"
                :border-width="2"
                :disable="true"
                border-color="#d8d8d8"
                :rounded-corners="false"
                :star-points="[
                  23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34,
                  46, 19, 31, 17,
                ]"
                :read-only="true"
                :show-rating="false"
              ></star-rating>
            </div>
          </div>
            <div class="ml-12 mt-4 w-full">
            <h4 class="headText">
              <p v-html="hospital.HospitalInfo.substring(0, numberOfWords)  "> </p> <u style="color:#00C524" v-if="numberOfWords==300" @click="numberOfWords=20000">{{$t('ShowMore')}}</u>
            </h4>
          </div>
        </div>
      </div>

      <div class="w-full justify-center">
        <div class="vx-row w-full" style="margin: auto">
          <div class="vx-col lg:w-1/2 w-full" >
            <div class="justfiy-center w-full mt-3">
              <h2 class="mt-8">
                {{ $t("MainInfo") }}
              </h2>
              <h4 class="mt-5">
                {{ $t("Country") }}:
                <div div class="w-full justify-center">
                  <v-select
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    name="Country"
                    class="w-full mt-5"
                    disabled
                    label="Name"
                    :options="countries"
                    :reduce="(ID) => ID.ID"
                    v-model="hospital.CountryID"
                  />
                </div>
              </h4>

              <h4 class="mt-5">
                {{ $t("Region") }}:
                <div div class="w-full justify-center">
                  <v-select
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    name="Region"
                    class="w-full mt-5"
                    disabled
                    :options="regions"
                    label="Name"
                    :reduce="(ID) => ID.ID"
                    v-model="hospital.RegionID"
                  />
                </div>
              </h4>
              <h4 class="mt-5">
                {{ $t("City") }}:
                <div div class="w-full justify-center">
                  <v-select
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    name="city"
                    class="w-full mt-5"
                    disabled
                    label="Name"
                    :options="cities"
                    :reduce="(ID) => ID.ID"
                    v-model="hospital.CityID"
                  />
                </div>
              </h4>
            </div>
          </div>
          <div class="vx-col lg:w-1/2 w-full" >
            <div class="justfiy-center w-full mt-12">
              <div>
                <h2 class="ml-8 mt-4" style="white-space:nowrap">{{ $t("HospitalImages") }}</h2>
              </div>
              <div class="vx-row w-full ml-5 mt-10">
                <div
                  v-for="item in hospital.HospitalAttachments"
                  :key="item.ID"
                  class="lg:w-1/4 "
                >
                  <div @click="show">
                    <img
                      style="border-radius: 10px; opacity: 1; width: 100%"
                      class="p-2"
                      :src="baseURL + item.AttachPath"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h1 class="justify-center mt-8">
        {{ $t("Accommodation") }}
      </h1>

      <h2 class="justify-center mt-5">
        {{ $t("HospitalRooms") }}
      </h2>

      <div class="ml-2 mt-4 ">
        <h4 class="headText">
        {{hospital.RoomDescription}}

        </h4>
      </div>
      <h2 class="justify-center mt-8 ml-2 mb-4">
        {{ $t("Facilities") }}
      </h2>

      <vs-row vs-lg="8" vs-sm="20" vs-justify="center" vs-type="flex">
        <vs-col
          v-for="item in facilities"
          :key="item.ID"
          class="justify-left mt-4"
          vs-sm="12"
          vs-lg="6"
          vs-type="flex"
        >
          <vs-row vs-w="12 "  vs-type="flex">
            <vs-col vs-sm="6" vs-lg="6">
              <span class="SpanTextClass ml-4">{{ item.Name }}</span>
            </vs-col>

            <vs-col vs-sm="6" vs-lg="6">
              <img
                style="width: 50px; height: 20px"
                alt=""
                :src="baseURL + item.ImagePath"
              />
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row>
      <div class="mt-8 mb-6 w-full ml-2 justify-left">
        <h2>
          {{ $t("Accompanyingperson") }}
        </h2>
      </div>

      <h4 class="mt-1 ml-4 w-full justify-left">
        {{
          $t(
            "Forinpatientsanaccompanyingpersonisallowedtostaywiththepatientintheroom"
          )
        }}
      </h4>
    </div>

     <vue-easy-lightbox
      scrollDisabled
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="slides"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>

  </div>
</template>
<script>
import moduleCity from "@/store/settings/city/moduleCity.js";
import moduleRegion from "@/store/settings/region/moduleRegion.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleHospitalGroup from "@/store/settings/HospitalGroup/moduleHospitalGroup.js";
import moduleFacility from "@/store/settings/facility/moduleFacility.js";
import moduleHospital from "@/store/hospital/moduleHospital.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import StarRating from "vue-star-rating";
import VueSplide from '@splidejs/vue-splide';
import VueEasyLightbox from 'vue-easy-lightbox';
import imageLazy from "@/components/image_lazy.vue";
import {
  VueFlux,
  FluxCaption,
  FluxControls,
  FluxIndex,
  FluxPagination,
  FluxPreloader,
} from "vue-flux";


export default {
  components: {
    imageLazy,
    VueSplide,
    StarRating,
     VueFlux,
    FluxCaption,
    FluxControls,
    FluxIndex,
    FluxPagination,
    FluxPreloader,
    VueEasyLightbox
  },
  data() {
    return {
      visible: false,
      index: 0,
      numberOfWords:300,
       options: {
          rewind : true,
          width  : 800,
          gap    : '1rem',
        },

         slides: [
      
    ],
    current: 0,
    width: 800,
    timer: 0,
      ImageSlider:false,
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      hospital: {
        HospitalAttachments: [],
        HospitalSpecialties: [],
        HospitalFacilities: [],
        
      },
      options: [],
      radios1: "luis",
    };
  },

  methods: {
      show() {
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
    ViewSlider()
    {
         this.ImageSlider=true;
    },
    getRegionByCountryId(CountryId) {
      this.$store
        .dispatch("RegionList/GetAllRegionsByCountryID", CountryId)
        .then(() => {
          if (this.hospital.RegionID > 0) {
            this.getCityByRegionId(this.hospital.RegionID);
          }
        });
    },
    getCityByRegionId(RegionId) {
      this.$store.dispatch("CityList/GetAllCitiesByRegionID", RegionId);
    },
  },
  computed: {
      swiper() {
        return this.$refs.mySwiper.$swiper
      },
    cities() {
      return this.$store.state.CityList.cities;
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
    regions() {
      return this.$store.state.RegionList.regions;
    },
    hospitalGroups() {
      return this.$store.state.HospitalGroupList.HospitalGroups;
    },
    facilities() {
      debugger;
      return this.$store.state.FacilityList.facilities;
    },
    filterHospitalAttachments() {
      return this.hospital.HospitalAttachments.filter((obj) => obj.ID > 0);
    },
  },
  created() {
   
    if (!moduleRegion.isRegistered) {
      this.$store.registerModule("RegionList", moduleRegion);
      moduleRegion.isRegistered = true;
    }
    if (!moduleCity.isRegistered) {
      this.$store.registerModule("CityList", moduleCity);
      moduleCity.isRegistered = true;
    }
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }

    if (!moduleHospitalGroup.isRegistered) {
      this.$store.registerModule("HospitalGroupList", moduleHospitalGroup);
      moduleHospitalGroup.isRegistered = true;
    }
    if (!moduleFacility.isRegistered) {
      this.$store.registerModule("FacilityList", moduleFacility);
      moduleFacility.isRegistered = true;
    }
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }

    this.$store.dispatch("FacilityList/GetAllFacilities");
    this.$store.dispatch("HospitalGroupList/GetAllHospitalGroups");
    this.$store.dispatch("CountryList/GetAllCountries");
    this.$store.dispatch("RegionList/GetAllRegions");
    this.$store.dispatch("CityList/GetAllCities");
    var id = this.$route.params.ID;

    if (id) {
      this.$store.dispatch("HospitalList/GetHospital", id).then((res) => {
        this.hospital = res.data.Data;
           var vm=this;
        this.slides=this.hospital.HospitalAttachments.map(ob=>vm.baseURL + ob.AttachPath)
        if (this.hospital.CountryID > 0) {
          this.getRegionByCountryId(this.hospital.CountryID);
        }
        if (this.hospital.HospitalFacilities != null) {
          this.hospital.HospitalFacilities.forEach((element) => {
            var index = this.facilities.findIndex(
              (x) => x.ID == element.FacilityID
            );
            debugger;
            if (index >= 0) this.facilities[index].IsChecked = true;
          });
        }
      });
    }
  },
  mounted(){
     this.swiper.slideTo(3, 1000, false)
  }
};
</script>
<style>
.txt-h4 {
  font-size: 1.3rem !important;

  line-height: 2.2rem;
}
.SpanTextClass {
  font-size: 1.1rem !important;
}

.headText {
  line-height: 1.8rem;
}
.vs__clear {
  display: none;
}
.feather-chevron-down {
  display: none;
}
h2 {
  color: #004477;
}
#imageSlider .flux-image
{
      width: 100%;
   
      border-radius: 25px;
}
#imageSlider .vs-popup--content
{
    height: 80vh;
}
</style>


<style lang="scss" >
#slider {
  width: 100%;
}

.slider {
  margin: 0 auto;
  padding: 0;
   width: 70vw;
    height: 80vh;
  position: relative;
  overflow: hidden;
  ul.slides {
    width: 70vw;
    height: 80vh;
    margin: 0;
    padding: 0;
    display: table;
    position: absolute;
    top: 0;
    transition: left 800ms;
    li {
      list-style-type: none;
      display: table-cell;
      img {
        border-radius: 20px;
         width: 70vw;
    height: 80vh;
      }
    }
  }
  ul.bullets {
    width: inherit;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0;
    margin: 0 0 10px 0;
    text-align: center;
    z-index: 1;
    li {
      list-style-type: none;
      display: inline;
      color: #fff;
      cursor: pointer;
      padding: 0 5px;
      font-size: 20px;
      font-family: sans-serif;
    }
  }
  .prev,
  .next {
    text-decoration: none;
    color: #fff;
    position: absolute;
    z-index: 1;
    font-size: 42px;
    top: 43%;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }
  .prev {
    left: 20px;
  }
  .next {
    right: 20px;
  }
}

</style>