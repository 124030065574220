<template>
  <div>
    <vs-card class="mt-5"  v-for="Specialty in doctorList"
            :key="Specialty.ID"
            >
             <h3 class="text-center mb-8">{{Specialty.SpecialtyName}}</h3>
        <div class="vx-row " >
         <div class="vx-col lg:w-1/6 w-full ml-5 mr-5 mb-5" v-for="doctor in Specialty.doctors"
            :key="doctor.ID">
                     <div class=" mr-8" >
                       <imageLazy
                      :imageStyle="{ 'border-radius': '50px','width':'150px', 'height':'150px' }"
                      :imageClass="'rounded-full'"
                      :src="baseURL + doctor.ProfileImagePath"
                      placeHolderType="doctor"
                    />
           

          </div>
          <div class="vx-col  mt-4 ">
             <h6 @click="DoctorProfile(doctor.ID)" style="cursor:pointer">{{ $t("Dr") }}. {{ doctor.Name }}</h6>
            <h6 class="mt-1">{{ $t("Title") }} : {{ doctor.Title }}</h6>
            <h6 class="mt-1">{{ $t("Address") }} : {{ doctor.Address }}</h6>
            <h6 class="mt-1" v-if="doctor.Bio">{{ $t("Biography") }} : {{ doctor.Bio.substring(0,100) }} ... </h6>

          </div>
         </div>
      </div>
    </vs-card>




  </div>
</template>
<script>
import HospitalModule from "@/store/hospital/moduleHospital.js";
import { domain } from "@/gloabelConstant.js";
import imageLazy from "@/components/image_lazy.vue";
export default {
  components: {
imageLazy
  },
  data() {
    return {
 doctorList:[],

      baseURL: domain,

    };
  },
  methods: {

  },
  computed: {

  },
  created() {
  if (!HospitalModule.isRegistered) {
      this.$store.registerModule("HospitalList", HospitalModule);
      HospitalModule.isRegistered = true;
    }
     var id = this.$route.params.ID;
    this.$store.dispatch("HospitalList/SearchHospitalSpecialtys", {"HospitalID":id}).then(res=>{
       this.doctorList = res.data.Data;

    });
  },
};
</script>
