<template>

<div>
    <div class="vx-row pb-10">

      <feather-icon v-if="EnglishLan" @click="back()" icon="ChevronLeftIcon" class="" style="width: 30px;cursor: pointer;">
      </feather-icon>
      <feather-icon v-else @click="back()" icon="ChevronRightIcon" style="width: 30px;cursor: pointer;">
      </feather-icon>
      <p class="text-xl font-bold fontSizeMedium">
        {{ $t("HospitalProfile") }}
      </p>
    </div>
    <div
    >

      <vx-card style="backgroun-color:none !important">
        <div slot="no-body" class="tabs-container px-8 pt-6">
          <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
            <vs-tab
              :label="$t('AboutHospital')"
              icon-pack="feather"
              icon="icon-user"
            >
              <div class="tab-text">
                <HospitalProfile class="" />
              </div>
            </vs-tab>
            <vs-tab
              :label="$t('HospitalDoctorSpecialities')"
              icon-pack="feather"
               icon="icon-crosshair"
            >
              <div class="tab-text">
                <HospitalSpecialities class=""  />
              </div>
            </vs-tab>

          </vs-tabs>
        </div>
      </vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">

            <vs-button type="border" color="danger" @click="back()">{{
              $t("Back")
            }}</vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { domain } from "@/gloabelConstant.js";
import HospitalProfile from "./HospitalProfile.vue";
import HospitalSpecialities from "./HospitalSpecialities.vue";
export default {
 data() {
    return {
      baseURL: domain,
       Model:[],
       activeTab: 0,
    EnglishLan:false,
     OldCount:0,
     CommingCount:0,

    };
  },
  components:{
 HospitalProfile,
 HospitalSpecialities
  },
   props: {

  },
 methods:{
       back() {
      this.$router.go(-1);
    }

 },
  created() {

 this.EnglishLan=localStorage.getItem("SaveLang")=="en"||localStorage.getItem("SaveLang")==null?true:false;


  }
};
</script>
<style >
.vs-tabs--li {
    display: block;
    width: 16rem;
    position: relative;

}
.vuesax-app-is-ltr .vs-tabs--btn-icon+span {
    padding-left: 9px;
    font-size:larger;
  letter-spacing: 1px;
  font-weight: bold;

}
</style>
